<template>
  <div class="info-container">
    <img class="question-ai" src="/ui-new/images/question-ai.png" alt="qs-image-col">
    <div class="info-header">LƯU Ý</div>
    <ul>
      <li>Các dữ liệu, sự kiện chỉ được cập nhật đến năm 2021</li>
      <li>Một số kết quả trả về có thể chứa dữ liệu không chính xác</li>
      <li>Một số hướng dẫn tạo ra có thể sai lệch hoặc gây hại cho người dùng</li>
      <li>Nội dung câu hỏi không được vi phạm thuần phong mỹ tục và mang yếu tố chống phá nhà nước</li>
      <li>Một số nội dung phản hồi cần được xem lại về mặt ngữ nghĩa</li>
    </ul>
<!--    <img class="mlearn" :src="require('@/assets/mlearn/icons/menu/logo.svg')"/>-->
  </div>
</template>

<script>
export default {
  name: 'Note'
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 310px;
  padding: 60px 4px 0;
  @media(max-width: 768px) {
    width: 90%;
    min-width: 280px;
  }

  ul {
    padding: 0 8px 0 30px;
    text-align: left;

    li {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #606060;
      margin-bottom: 10px;
      text-align: left;
    }
  }

  .mlearn {
    width: auto;
    height: 22px;
    margin-top: 10px;
    margin-right: 20px;
    float: right;
  }

  .question-ai {
    display: none;
    width: 200px;
    height: 206px;
    border-radius: 120px;
    margin: 0 auto;
    @media(max-width: 768px) {
      display: block;
    }
  }
}

.info-header {
  position: relative;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 25px;
  line-height: 60px;
  color: #606060;
  border-bottom: 2px solid #0061AF;
  margin-bottom: 60px;

  &::before {
    content: '';
    display: block;
    width: 46.67px;
    height: 46.67px;
    background: #fff;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -25px;
    left: -24px;
    border-bottom: 2px solid #0061AF;
    transform-origin: center;
  }

  @media(max-width: 768px) {
    border: none;
    margin-bottom: 20px;
    &::before {
      display: none;
    }
  }

}

</style>
