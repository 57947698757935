<template>
  <img src="" @error="replaceByDefault" ref="image">
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'avatar'
    },
    src: {
      type: String,
      default: ''
    }
  },
  watch: {
    src (newValue, oldValue) {
      if (this.src) {
        this.$refs.image.src = this.src
        return
      }
      if (this.src === null) {
        this.setSrc()
      }
    }
  },
  mounted () {
    if (this.src) {
      this.$refs.image.src = this.src
      return
    }
    if (this.src === null) {
      this.setSrc()
    }
  },
  methods: {
    replaceByDefault (e) {
      this.setSrc()
    },
    setSrc () {
      switch (this.type) {
        case 'avatar':
          this.$refs.image.src = require('~/assets/images/icons/no_avatar.svg')
          break
        case 'video':
          this.$refs.image.src = require('~/assets/images/no-image.png')
          break
        case 'document':
          this.$refs.image.src = require('~/assets/images/no-image.png')
          break
        case 'lesson':
          this.$refs.image.src = require('~/assets/icons/library/no-video.png')
          break
        default:
          this.$refs.image.src = require('~/assets/images/no-image.png')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
