<template>
  <div class="chat-body" ref="messageCont">
    <div class="container">
      <template v-for="(conversation,i) in oldConversation">
        <p class="text-center" :key="i">{{ formatTime(conversation.created_at) }}</p>
        <template v-for="(question,j) in conversation.questionList">
          <div class="wrapper" :key="'0'+i+j">
            <Chat :chat="{ isAi: false, value: question.question}"/>
          </div>
          <div class="wrapper ai" :key="'1'+i+j">
            <Chat :chat="{ isAi: true, value: question.answer}"/>
          </div>
        </template>
      </template>
      <p v-if="wrapper.length > 0 && oldConversation.length > 0" class="text-center">{{ wrapper[0].created_at }}</p>
      <div
        v-for="(chat, i) in wrapper"
        :key="i"
        class="wrapper"
        :class="{ ai: chat.isAi }"
      >
        <Chat :chat="chat" :key="i"/>
      </div>
      <!--      <div class="d-block" style="height: 200px" v-if="wrapper.length == 1"></div>-->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {ref} from 'vue'
import Chat from '~/components/ai/Chat.vue'

export default {
  name: 'MessageCont',
  components: {
    Chat
  },
  props: {
    wrapper: {
      type: Array
    },
    oldConversation: {
      type: Array
    }
  },
  setup (props) {
    const messageCont = ref(null)
    return {messageCont}
  },
  updated () {
    const div = this.$refs.messageCont
    div.scroll({top: div.scrollHeight, behavior: 'smooth'})
    div.scrollIntoView({behavior: 'smooth', block: 'end'})
  },
  watch: {
    'oldConversation.length': {
      handler () {
        const div = this.$refs.messageCont
        div.scroll({top: div.scrollHeight, behavior: 'smooth'})
        div.scrollIntoView({behavior: 'smooth', block: 'end'})
      }
    },
    wrapper: {
      handler () {
        const div = this.$refs.messageCont
        div.scroll({top: div.scrollHeight, behavior: 'smooth'})
        div.scrollIntoView({behavior: 'smooth', block: 'end'})
      }
    }
  },
  methods: {
    formatTime (time) {
      const NOW = this.$moment()
      const TODAY = NOW.clone().startOf('day')
      const YESTERDAY = NOW.clone().subtract(1, 'days').startOf('day')
      // const A_WEEK_OLD = NOW.clone().subtract(7, 'days').startOf('day')
      time = this.$moment(time)
      if (time.isSame(TODAY, 'day')) {
        return time.format('HH:mm')
      } else if (time.isSame(YESTERDAY, 'day')) {
        return time.format('[Hôm qua] HH:mm')
      } else if (time.isSame(NOW, 'year')) {
        return time.format('HH:mm, D [Tháng] M')
      }
      // else if (time.isAfter(A_WEEK_OLD)) {
      //   const dayOfWeek = time.isoWeekday()
      //   switch (dayOfWeek) {
      //     case 1:
      //     case 2:
      //     case 3:
      //     case 4:
      //     case 5:
      //     case 6:
      //       return time.format(`[T${dayOfWeek + 1}] HH:mm`)
      //     case 7:
      //       return time.format('[CN] HH:mm')
      //   }
      //   return time.locale('vi').format('ddd HH:mm')
      // }
      return time.format('HH:mm, D [Tháng] M, YYYY')
    }
  }
}
</script>
